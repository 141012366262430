import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, reactive, toRaw, computed, toRefs, onMounted, ref } from 'vue';
import { selectJobByParam, selectDepartmentList } from "/src/api/personnel";
import useTablePagination from '@/hooks/pagination';
import { selectClockInRecordByPagination } from "/src/api/attendance";
import { useRouter } from 'vue-router';
import { selectDeviceSettings } from '/src/api/global';
export default defineComponent({
  setup() {
    onMounted(() => {
      getJobData();
      selectCheckindayByPagination1();
    });
    let tableData = ref([]);
    let columns = ref([{
      title: '姓名',
      dataIndex: 'name'
    }, {
      title: '工号',
      dataIndex: 'workNo'
    }, {
      title: '所属部门',
      dataIndex: 'departmentName'
    }, {
      title: '打卡时间',
      dataIndex: 'clockTime'
    }]); ///////////////

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;

      if (isFilter) {
        onSubmit();
      } else {
        selectCheckindayByPagination1();
      }
    };

    const isFilter = ref(false);
    const tableLoading = ref(false);
    const btnLoading = ref(false); //筛选信息

    const formState = reactive({
      name: '',
      workNo: null,
      departmentId: null
    });

    const onSubmit = () => {
      let submitData = {
        userName: formState.name,
        workNo: formState.workNo,
        departmentId: formState.departmentId !== null ? Number(formState.departmentId) : null,
        pageNumber: current.value == 0 ? '1' : current.value,
        limit: pageSize.value == 0 ? '10' : pageSize.value
      };
      filterCheckindayByPagination1(submitData);
    };

    const reset = () => {
      btnLoading.value = false;
      Object.assign(formState, {
        name: '',
        workNo: null,
        departmentId: null
      });
      current.value = '1';
      pageSize.value = '10';
      selectCheckindayByPagination1();
    }; //人员岗位


    const jobsData = ref([]); //获取岗位

    const getJobData = async () => {
      let res = await selectJobByParam({
        jobName: '',
        jobType: 0
      });

      if (res.code === 200) {
        jobsData.value = [];
        res.data.forEach(element => {
          jobsData.value.push({
            value: element.id,
            label: element.jobName,
            jobType: element.jobType
          });
        });
      } else {
        _message.error(res.message);
      }
    }; //获取当日考勤数据


    const selectCheckindayByPagination1 = async () => {
      tableLoading.value = true;
      let res = await selectClockInRecordByPagination({
        pageNumber: current.value == 0 ? '1' : current.value,
        limit: pageSize.value == 0 ? '10' : pageSize.value
      });

      if (res.code === 200) {
        tableLoading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        isFilter.value = false;
        tableData.value = [];

        if (list != null) {
          if (list[0].channelType == 1) {
            columns.value.push({
              title: '进入/离开',
              dataIndex: 'deviceType'
            });
          }

          list.filter((item, index) => {
            tableData.value.push({
              key: index + 1,
              name: item.userName,
              workNo: item.workNo,
              departmentId: item.departmentId,
              departmentName: item.departmentName,
              clockTime: item.recordTime,
              deviceType: item.deviceType == 0 ? '进入' : '离开',
              channelType: item.channelType
            });
          });
        }
      } else {
        tableLoading.value = false;

        _message.error(res.message);
      }
    }; //筛选当日考勤数据


    const filterCheckindayByPagination1 = async val => {
      tableLoading.value = true;
      let res = await selectClockInRecordByPagination(val);

      if (res.code === 200) {
        tableLoading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        isFilter.value = true;
        tableData.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableData.value.push({
              key: index + 1,
              name: item.userName,
              workNo: item.workNo,
              departmentId: item.departmentId,
              departmentName: item.departmentName,
              clockTime: item.recordTime
            });
          });
        }
      } else {
        tableLoading.value = false;

        _message.error(res.message);
      }
    };

    const router = useRouter();

    const goCurrent = () => {
      router.push('/currentHeadcount');
    };

    const channelType = ref(0); //获取通道设置

    const channelSet = async () => {
      let res = await selectDeviceSettings();

      if (res.code === 200) {
        channelType.value = res.data.channelType;
      } else {
        _message.error(res.message);
      }
    }; //部门信息


    const departmentData = ref([]);

    const getDepartmentList = async () => {
      let res = await selectDepartmentList();

      if (res.code === 200) {
        departmentData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.id,
              label: item.departmentName
            };
            departmentData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      channelSet();
      getDepartmentList();
    });
    return {
      tableData,
      columns,
      formState,
      onSubmit,
      jobsData,
      tableLoading,
      btnLoading,
      reset,
      paginationConfig,
      onPageChange,
      goCurrent,
      channelType,
      departmentData
    };
  }

});